<template>
	<div>
		<section class="tf-explore tf-section bd-all-collections-page">
			<div class="themesflat-container">
				<!-- mobile options -->
				<div class="bd-mobile" style="margin-top: 00px;">
					<h1>Explore Collections</h1>
				</div>
				<div class="bd-mobile" style="margin-top: 20px; margin-bottom: 00px;">
					<div style="display: flex; justify-content: space-between;">
						<div class="seclect-box style-2 box-right" style="width: auto !important;">
							<div id="collection_filters" class="dropdown bd-dropdown">
								<a class="btn-selector nolink bd-dropdown-link" style="min-width: 130px;">{{ display_sorting() }}</a>
								<ul>
									<li @click="filters.sorting='volume.asc';reload_list();"><span>Volume, low to high</span></li>
									<li @click="filters.sorting='volume.desc';reload_list();"><span>Volume, high to low</span></li>
									<li @click="filters.sorting='floor_price.asc';reload_list();"><span>Floor price, low to high</span></li>
									<li @click="filters.sorting='floor_price.desc';reload_list();"><span>Floor price, high to low</span></li>
									<li @click="filters.sorting='mint_date.asc';reload_list();"><span>Mint date, old to recent</span></li>
									<li @click="filters.sorting='mint_date.desc';reload_list();"><span>Mint date, recent to old</span></li>
									<li @click="filters.sorting='mint_price.asc';reload_list();"><span>Mint price, low to high</span></li>
									<li @click="filters.sorting='mint_price.desc';reload_list();"><span>Mint price, high to low</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<!-- mobile filters -->
				<div class="row bd-mobile">
					<div class="col-xl-12 col-lg-12 col-md-12">
						<div class="badge bd-tag active" style="display: block; margin-top: 30px; font-weight: 500;" @click="toggle_mobile_display_filters()">
							<i class="fas fa-filter" style="margin-right: 10px;"></i>
							Filters
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-xl-3 col-lg-3 col-md-12 bd-all-collections-filters" :class="{display: mobile_display_filters === true}">
						<div id="side-bar" class="side-bar style-3">
              <div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Tags</h4>
								</div>
								<div class="content-wg-category">
                  <div class="row">
                    <div class="col-sm-8">
                      <div class="badge bd-tag" :class="{active: filters.tags.length === 0}" @click="filters.tags = [];reload_list()">
                        All Categories
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <i @click="show_filters_tags = !show_filters_tags" class="bd-button-filter-tags fas fa-angle-double-down" :class="{active: show_filters_tags}"></i>
                    </div>
                  </div>
                  <transition name="bd-filters-tag">
                    <div class="bd-filters-tag" v-if="show_filters_tags">
                      <div class="badge bd-tag" :class="{active: filters.tags.includes(tag)}" v-for="(tag,index_value) in tags" :key="index_value" @click="add_filter_tag(tag, 'bouton')">
                        {{tag.name}}
                        <input type="checkbox" :checked="filters.tags.includes(tag)" :id="'checkbox_tag_'+tag.id">
                      </div>
                    </div>
                  </transition>
                  <div class="bd-filters-tag" v-if="!show_filters_tags">
                    <div class="badge bd-tag active" v-for="(tag,index_value) in tags_activate" :key="index_value" @click="add_filter_tag(tag, 'bouton')">
                      {{tag.name}}
                      <input type="checkbox" :checked="filters.tags.includes(tag)" :id="'checkbox_tag_'+tag.id">
                    </div>
                  </div>
								</div>
							</div>
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Volume</h4>
								</div>
								<div class="content-wg-category bd-slider-container">
									<form action="#">
										<Slider v-model="filters.volume.value" :min="filters.volume.min" :max="filters.volume.max" :merge="filters.volume.max / 5" @update="reload_list"></Slider>
									</form>
								</div>
							</div>
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Floor price</h4>
								</div>
								<div class="content-wg-category bd-slider-container">
									<form action="#">
										<Slider v-model="filters.floor_price.value" :min="filters.floor_price.min" :max="filters.floor_price.max" :merge="filters.floor_price.max / 5" :step=0.01 :format="format_filter_price" @update="reload_list"></Slider>
									</form>
								</div>
							</div>
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Mint price</h4>
								</div>
								<div class="content-wg-category bd-slider-container">
									<form action="#">
										<Slider v-model="filters.mint_price.value" :min="filters.mint_price.min" :max="filters.mint_price.max" :merge="filters.mint_price.max / 5" :step=0.01 :format="format_filter_price" @update="reload_list"></Slider>
									</form>
								</div>
							</div>
              <div class="widget mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Mint date</h4>
								</div>
								<div class="content-wg-category">
									<form action="#">
                    <div style="width:100%">
                      <label class="bd-label-date-filter">
                        <span>From</span>
                        <input type="date" class="bd-input-date-filter" name="from" v-model="filters.mint_date.from" @change="reload_list" />
                      </label>
                      <label class="bd-label-date-filter">
                        <span>To</span>
                        <input type="date" class="bd-input-date-filter" name="to" v-model="filters.mint_date.to" @change="reload_list"/>
                      </label>
                    </div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-12 bd-collection-all-page-collection-zone">
					
					
					
					<div style="display: inline-flex;width: 100%; margin-bottom: 5%;" class="bd-desktop">
						<div class="seclect-box style-2 box-left" style="width: 100%;">
							<h1>Explore Collections</h1>
						</div>
						<div class="seclect-box style-2 box-right" style="width: 100%;">
							<div id="collection_filters" class="dropdown bd-dropdown">
								<a class="btn-selector nolink" style="width: 200px;">{{ display_sorting() }}</a>
								<ul>
									<li @click="filters.sorting='volume.asc';reload_list();"><span>Volume, low to high</span></li>
									<li @click="filters.sorting='volume.desc';reload_list();"><span>Volume, high to low</span></li>
									<li @click="filters.sorting='floor_price.asc';reload_list();"><span>Floor price, low to high</span></li>
									<li @click="filters.sorting='floor_price.desc';reload_list();"><span>Floor price, high to low</span></li>
									<li @click="filters.sorting='mint_date.asc';reload_list();"><span>Mint date, old to recent</span></li>
									<li @click="filters.sorting='mint_date.desc';reload_list();"><span>Mint date, recent to old</span></li>
									<li @click="filters.sorting='mint_price.asc';reload_list();"><span>Mint price, low to high</span></li>
									<li @click="filters.sorting='mint_price.desc';reload_list();"><span>Mint price, high to low</span></li>
								</ul>
							</div>
						</div>
					</div>
					
						<h2 v-if="collections.length === 0" style="text-align:center"> No result found </h2>
						<div class="box-epxlore" id="collection-box">
							<CollectionCard :collection="collection" v-for="(collection, key) in collections" :key="key"></CollectionCard>
							<div v-if="modulo >= 1" class="sc-card-product bd-empty-nftcard three-per-line"></div>
							<div v-if="modulo >= 2" class="sc-card-product bd-empty-nftcard three-per-line"></div>
							
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

import CollectionCard from '@/components/CollectionCard.vue';
import Slider from '@vueform/slider/dist/slider.vue2.js';
import $ from 'jquery';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export default {
  name: 'Collection',
  components: {
	CollectionCard,
  Slider,
  },
  data: function () {

	return {

		// url_data: this.$route.params.id,
    collections: {},
		mobile_display_filters: false,
		route_server: process.env.VUE_APP_ROOT_API,
		route_bo: process.env.VUE_APP_ROOT_BO,
    filters: {
      number_per_page : 15,
      sorting: 'volume.asc',
      floor_price: {
        value: [0, 500],
        min: 0,
        max: 500,
      },
      mint_price: {
        value: [0, 500],
        min: 0,
        max: 500,
      },
      volume: {
        value: [0, 500],
        min: 0,
        max: 500,
      },
      mint_date: {
        from: '',
        to : '',
      },
      tags: [],
    },
    reload_activate:false,
    count_elements: 0,
    tags: {},
    tag: this.$route.params.tag,
    show_filters_tags: false,
	}
  },
  props: {

  },
  methods: {

    reload_list: function(e,initialize = false,scroll = false){

      var $this = this;

      this.reload_activate = true;

      var filters = $this.filters;

      if(initialize === true)
        filters['initialize'] = true;

      if(scroll === false)
        delete filters.number_per_page;

      axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/reload_list',filters, config_axios).then(function(elements) {

        if(scroll === true)
          $this.collections = $this.collections.concat(elements.data.elements);
        else {
          $this.collections = elements.data.elements;
          filters.number_per_page = 15;
        }

        if(initialize === true) {
          filters.mint_price = elements.data.filters.mint_price;
          filters.floor_price = elements.data.filters.floor_price;
          filters.volume = elements.data.filters.volume;

          $this.tags = elements.data.tags;

          delete filters['initialize'];
        }

        $this.count_elements = elements.data.count_elements;

        $this.reload_activate = false;

        $this.$forceUpdate();
		
		for (const [key, tag] of Object.entries($this.tags)) {
          
          if (tag.id.toString() == $this.tag){
			
            $this.add_filter_tag(tag);
            $this.tag = undefined;
            $this.$forceUpdate();
            $this.reload_list();
          }
        }
		
      });

    },
	
	toggle_mobile_display_filters: function() {

		if(this.mobile_display_filters === true)
			this.mobile_display_filters = false;
		else
			this.mobile_display_filters = true;
	},
	
    scroll () {
      window.onscroll = () => {
         var id_element = 'collection-box';

        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.getElementById(id_element).offsetHeight

        if (bottomOfWindow && this.reload_activate === false && this.count_elements > this.filters.number_per_page) {
          this.reload_list(false, false, true);
          this.filters.number_per_page += 15;
        }
      }
    },
    display_sorting: function() {

      if(this.filters.sorting == 'volume.asc')
        return 'Volume, low to high';

      if(this.filters.sorting == 'volume.desc')
        return 'Volume, high to low';

      if(this.filters.sorting == 'floor_price.asc')
        return 'Floor price, low to high';

      if(this.filters.sorting == 'floor_price.desc')
        return 'Floor price, high to low';

      if(this.filters.sorting == 'mint_date.asc')
        return 'Volume, old to recent';

      if(this.filters.sorting == 'mint_date.desc')
        return 'Volume, recent to old';

      if(this.filters.sorting == 'mint_price.asc')
        return 'Mint price, low to high';

      if(this.filters.sorting == 'mint_price.desc')
        return 'Mint price, high to low';

    },

    format_filter_price: function (value) {
      return '◎ '+Math.round((value + Number.EPSILON) * 100) / 100;
    },

    add_filter_tag: function(tag, test){

      var match_filter = false;

      for (const [key, filter_activate] of Object.entries(this.filters.tags)) {
        if(tag.id === filter_activate.id)
          match_filter = key;
      }

      if(match_filter)
        this.filters.tags.splice(match_filter,1);
      else
        this.filters.tags.push(tag);

      this.reload_list();
    },

  },
  computed:{
	modulo: function() {
			
			if(this.collections == undefined)
				return 0;
				
			if(this.collections == undefined)
				return 0;
				
			if(this.collections.length == 0)
				return 0;
				
			return 3 - parseInt(this.collections.length % 3);
			
			
		},
    tags_activate: function(){
      var tags_activate = [];
      var component_vue = this;

      $.each(this.tags,function(index,tag){
        if(component_vue.filters.tags.includes(tag))
          tags_activate.push(tag);
      });

      return tags_activate;
    },
  },
  created: function() {

	var $this = this;

	
  $this.reload_list(false,true);

  },
  mounted () {
	window.scrollTo(0, 0)
    this.scroll();
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

